/**
 * PC端自适应rem配置文件
 * @designWidth 设计宽度
 */
;
(function (win) {
  let tid; //计时器
  function refreshRem() {
    let designWidth = 1920; // 设计图尺寸
    let nowWidth = document.documentElement.clientWidth; // 获取当前窗口宽度，如1280
    let rem = nowWidth * 100 / designWidth; //
    document.documentElement.style.fontSize = rem + 'px';
  }
  win.addEventListener('resize', function () {
    clearTimeout(tid);
    tid = setTimeout(refreshRem, 300);
  }, false);
  win.addEventListener('pageshow', function (e) {
    if (e.persisted) {
      clearTimeout(tid);
      tid = setTimeout(refreshRem, 300);
    }
  }, false);
  refreshRem();
})(window);