import Vue from 'vue';
Vue.directive('has', {
  inserted: function (el, binding) {
    const {
      value
    } = binding
    const permissionArr = JSON.parse(window.localStorage.getItem('playOne-store-permissionArr'));
    let flag = permissionArr.some(i => i == value);
    if (!flag) {
      el.style.display = "none";
    }
  }
})